import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation, Injector, ChangeDetectionStrategy, ViewChild, ElementRef, NgZone, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '../../base.component'
import { ApiService } from '../../api.service';
import { ColorPickerService } from '../../components/color-picker/color-picker.service';
import { SwatchModel } from "../../models/swatch.model";
import { SwatchChangedEvent } from "../../events/swatch.changed.event";
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Options } from '@angular-slider/ngx-slider';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as cloneDeep from 'lodash/cloneDeep';
import { delay } from 'underscore';
declare var jQuery: any;

@Component({
	selector: 'app-palette-builder',
	templateUrl: './palette-builder.component.html',
	styleUrls: ['./palette-builder.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaletteBuilderComponent extends BaseComponent implements OnInit {
	value: number = 4;

	screenWidth = window.innerWidth - 560
	setWidth = window.innerHeight - 115
	showWorkSpace = false
	previewModal = false
	zccdeltaE = 0.0
	Arr = Array
	constructor(private spinner: NgxSpinnerService, public ref: ChangeDetectorRef, private _sanitizer: DomSanitizer, private modalService: NgbModal, public confirmationDialogService: ConfirmationDialogService, public injector: Injector, public _zone: NgZone, public apiService: ApiService, public service: ColorPickerService, private router: Router) {
		super(injector)
	}

	private _swatchChangedSubscription: any

	ngOnDestroy() {
		this.gm.selectedSwatchPickerStack = []
		// if (!this._swatchChangedSubscription.closed) {
		// 	this._swatchChangedSubscription.unsubscribe();
		// }
		this.createScrollScript()
	}


	public harmonyPaletteOptions;
	public theoryPaletteOptions;

	public selectedColorOption;

	public swatchNumbers;
	public selectedSwatchNumber;
	public isSwatchNumberDisabled = true
	ngOnInit(): void {

		this.screenWidth = window.innerWidth
		this.setWidth = window.innerHeight - 115

		this.harmonyPaletteOptions = [
			{
				'formatID': 'hueharmony',
				'formatText': 'Hue & Harmony'
			},
			{
				'formatID': 'spectral',
				'formatText': 'Spectral'
			},
			{
				'formatID': 'shade',
				'formatText': 'Shade'
			},
			{
				'formatID': 'tint',
				'formatText': 'Tint'
			}
			// {
			// 	'formatID': 'splittonal',
			// 	'formatText': 'Split Tonal'
			// },
		]

		this.theoryPaletteOptions = [
			{
				'formatID': 'complementary',
				'formatText': 'Complementary'
			},
			{
				'formatID': 'splitcomplementary',
				'formatText': 'Split Complementary'
			},
			{
				'formatID': 'triad',
				'formatText': 'Triad'
			},
			{
				'formatID': 'terad',
				'formatText': 'Terad'
			},
			{
				'formatID': 'square',
				'formatText': 'Square'
			},
			{
				'formatID': 'analogous',
				'formatText': 'Analogous'
			},
		]

		this.swatchNumbers = ['06', '12', '18', '24']
	}

	swatchNumberSelected(value: any) {
		this.selectedSwatchNumber = value

	}

	selectedPalleteOption(value: any) {
		this.selectedColorOption = value;
		var item = this.harmonyPaletteOptions.find(obj => {
			return obj.formatID == this.selectedColorOption
		})
		if (item != undefined) {
			this.isSwatchNumberDisabled = false
		}
		else {
			this.isSwatchNumberDisabled = true
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.screenWidth = window.innerWidth
		this.setWidth = window.innerHeight - 115
	}


	swatchChanged(o: SwatchChangedEvent): void {

	}


	showWorkspace(text: any) {
		jQuery('#page-title').html(text);
		this.createScrollScript()
		this.showWorkSpace = !this.showWorkSpace;
		this.ref.markForCheck();
	}

	//validation methods

	isValidHex(hex) {
		if (hex.length == 0) {
			return false
		}
		hex = (hex.charAt(0) === "#") ? hex.substring(1, 7) : hex

		if (hex.length !== 6) {

			return false
		}
		if (/[0-9a-f]{6}/i.test(hex) !== true) {
			// alert ('Invalid digits in the input hex value!')
			return false
		}
		return true
	}

	isValidNumber($event) {

		var charCode = ($event.which) ? $event.which : $event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		let val = $event.srcElement.value;
		let valR = Number(val + $event.key)
		if (valR > 255) {
			return false
		}
		return true;
	}
	isCMYK(event) {
		var charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		let val = event.srcElement.value
		let valR = Number(val + event.key)
		if (valR > 100) {
			return false
		}
		return true;
	}

	//conversion methods
	hexTorgb(hex: string) {
		hex = (hex.charAt(0) === "#") ? hex.substring(1, 7) : hex

		if (hex.length !== 6) {

			return
		}
		if (/[0-9a-f]{6}/i.test(hex) !== true) {
			// alert ('Invalid digits in the input hex value!')
			return
		}

		let r = parseInt(hex.substring(0, 2), 16)
		let g = parseInt(hex.substring(2, 4), 16)
		let b = parseInt(hex.substring(4, 6), 16)

		return { r: r, g: g, b: b }
	}
	//h in 360 s in 100 l in 100 format
	hslToHex(h, s, l) {
		l /= 100;
		const a = s * Math.min(l, 1 - l) / 100;
		const f = n => {
			const k = (n + h / 30) % 12;
			const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
			return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
		};
		return `#${f(0)}${f(8)}${f(4)}`;
	}
	rgbToHsl(ra, ga, ba) {
		var r = ra / 255,
			g = ga / 255,
			b = ba / 255,
			cMax = Math.max(r, g, b),
			cMin = Math.min(r, g, b),
			delta = cMax - cMin,
			l = (cMax + cMin) / 2,
			h = 0,
			s = 0;

		if (delta == 0) {
			h = 0;
		}
		else if (cMax == r) {
			h = 60 * (((g - b) / delta) % 6);
		}
		else if (cMax == g) {
			h = 60 * (((b - r) / delta) + 2);
		}
		else {
			h = 60 * (((r - g) / delta) + 4);
		}

		if (delta == 0) {
			s = 0;
		}
		else {
			s = (delta / (1 - Math.abs(2 * l - 1)))
		}

		if (h < 0) {
			h = h + 360;
		}
		// else if (h == 0) {
		//   h = 360;
		// }

		// return { hue: Math.round(h), saturation: Math.round(100 * s), lightness: Math.round(100 * l) };
		if (h == 0) {
			let hexValue = '#' + this.rgbToHex(ra, ga, ba)
			let layerColors = ["#FFFFFF", "#000000", "#090909", "#F7F7F7", "#EEEEEE", "#111111", "#1A1A1A", "#E6E6E6", "#DDDDDD", "#222222", "#2B2B2B", "#D5D5D5", "#CCCCCC", "#333333", "#3C3C3C", "#C4C4C4", "#BBBBBB", "#444444", "#4D4D4D", "#B3B3B3", "#AAAAAA", "#555555", "#5E5E5E", "#A2A2A2", "#999999", "#666666", "#6F6F6F", "#919191", "#888888", "#777777", "#808080"]
			if (layerColors.includes(hexValue)) {
				h = 0
			}
			else {
				h = 360
			}
		}
		return { h: Math.round(h), s: Math.round(Math.round(100 * 100 * s) / 100), l: Math.round(Math.round(100 * 100 * l) / 100) };
	}

	rgbToHex(R, G, B): string {
		return this.toHex(R) + this.toHex(G) + this.toHex(B);
	}
	toHex(n): any {
		n = parseInt(n, 10);
		if (isNaN(n)) return "00";
		n = Math.max(0, Math.min(n, 255));
		return "0123456789ABCDEF".charAt((n - n % 16) / 16)
			+ "0123456789ABCDEF".charAt(n % 16);
	}

	rgb2cmyk(r, g, b): any {
		var computedC = 0;
		var computedM = 0;
		var computedY = 0;
		var computedK = 0;

		//remove spaces from input RGB values, convert to int
		r = parseInt(('' + r).replace(/\s/g, ''), 10);
		g = parseInt(('' + g).replace(/\s/g, ''), 10);
		b = parseInt(('' + b).replace(/\s/g, ''), 10);

		if (r == null || g == null || b == null ||
			isNaN(r) || isNaN(g) || isNaN(b)) {
			// alert ('Please enter numeric RGB values!');
			return;
		}
		if (r < 0 || g < 0 || b < 0 || r > 255 || g > 255 || b > 255) {
			// alert ('RGB values must be in the range 0 to 255.');
			return;
		}

		// BLACK
		if (r == 0 && g == 0 && b == 0) {
			return {
				c: 0,
				m: 0,
				y: 0,
				k: 100
			}
		}

		computedC = 1 - (r / 255);
		computedM = 1 - (g / 255);
		computedY = 1 - (b / 255);

		var minCMY = Math.min(computedC, Math.min(computedM, computedY));

		computedC = (computedC - minCMY) / (1 - minCMY);
		computedM = (computedM - minCMY) / (1 - minCMY);
		computedY = (computedY - minCMY) / (1 - minCMY);

		computedK = minCMY;


		return {
			c: Math.round(100 * (Math.round(computedC * 1000) / 1000)),
			m: Math.round(100 * (Math.round(computedM * 1000) / 1000)),
			y: Math.round(100 * (Math.round(computedY * 1000) / 1000)),
			k: Math.round(100 * (Math.round(computedK * 1000) / 1000))
		};
	}


	cmyk2rgb(c, m, y, k): any {
		c = c / 100;
		m = m / 100;
		y = y / 100;
		k = k / 100;

		var r = 255 * (1 - c) * (1 - k);
		var g = 255 * (1 - m) * (1 - k);
		var b = 255 * (1 - y) * (1 - k);

		r = Math.round(r * 1) / 1;
		g = Math.round(g * 1) / 1;
		b = Math.round(b * 1) / 1;

		return {
			r: r,
			g: g,
			b: b
		}
	}

	cmyk2hex(c, m, y, k): string {
		var o = this.cmyk2rgb(c, m, y, k);

		return this.rgbToHex(o.r, o.g, o.b);
	}


	tagandSave() {
		this.router.navigateByUrl('/swatch-exchange');
	}

	movetosample() {
		this.router.navigateByUrl('/sample');
	}

	addSwatchToWorkspace() {

	}


	undoSelectedPickerSwatch() {
	}


	undoDelete() {
		this.gm.undoStack();
	}
	clearAllPallets() {
		this.gm.addToRedoStack();
		let isNonEmptyIndex = this.gm.palettesModel.selectedPalette.swatches.findIndex(elem => elem.empty == false)
		if (isNonEmptyIndex >= 0) {
			this.removeAllPallets();
		}
	}

	removeAllPallets() {
		let palette = this.gm.palettesModel.selectedPalette;
		this.gm.removeAllPalletes()
		this.gm.palettesModel.writeDefaultFile();
		this.ref.markForCheck();
	}

	deleteSelectedSwatch() {
		if (this.gm.palettesModel.selectedSwatch.selected) {
			let swatch = this.gm.palettesModel.selectedSwatch
			let palette = this.gm.palettesModel.selectedPalette
			if (swatch.empty) {
				alert("The swatch is already empty.");
				return;
			} else {
				this.gm.addToRedoStack();
				for (var i = 0; i < palette.swatches.length; i++) {
					if (palette.swatches[i].id === swatch.id) {
						let deletedSwatch = Object.assign({}, palette.swatches[i]);
						let index = this.gm.palettesModel.selectedPalette.swatches.findIndex(x => x.id == palette.swatches[i].id);
						let deletedArr = { index: index, data: deletedSwatch };
						this.gm.deletedStack.push(deletedArr);
						palette.swatches[i].empty = true;
						palette.swatches[i].zcc = "ZCC-0000"
						palette.swatches[i].contrastingFontColor = "white";
						// this.gm.palettesModel.selectedSwatch = new SwatchModel({});
						this.gm.palettesModel.updatePaletteWithoutSelectingSwatch(palette);
						this.gm.palettesModel.selectedSwatch.selected = false
						this.gm.palettesModel.writeDefaultFile();
					}
				}
			}
		}

	}

	//api interaction methods
	convertHexToZCC(hex) {
		if (this.isValidHex(hex) == false) {
			return
		}
		let self = this
		self.spinner.show();
		return new Promise(function (resolve, reject) {
			self.apiService.hexToZccWithCF(hex, 1, 1, 1).subscribe((data: any) => {
				self.spinner.hide();
				let zccItem = { zcc: "", hex: "", cmyk: { c: "", m: "", y: "", k: "" }, rgb: { r: "", g: "", b: "" }, hue: "", saturation: "", lightness: "", cubeFace: "", layer: "" }
				zccItem.zcc = data.zcc
				zccItem.hex = "#" + data.hex;
				zccItem.rgb = data.rgb;
				zccItem.cmyk = data.cmyk;
				zccItem.hue = Math.round(data.hsl.h).toString()
				zccItem.saturation = Math.round(data.hsl.s * 100).toString()
				zccItem.lightness = Math.round(data.hsl.l * 100).toString()
				zccItem.cubeFace = data.cubeFace
				zccItem.layer = data.layer
				let result = { zccItem: zccItem, deltaE: data.deltaE.toFixed(1) }
				resolve(result)
			}, (error) => {
				self.spinner.hide();
				alert(error.error.message)
			})
		})
	}

	public selectedSwatchInfo
	getZCCHEX(zcc) {
		let self = this
		self.spinner.show();
		return new Promise(function (resolve, reject) {
			self.apiService.zccToHex(zcc).subscribe((data: any) => {
				self.spinner.hide();
				let zccItem = { zcc: "", hex: "", cmyk: { c: "", m: "", y: "", k: "" }, rgb: { r: "", g: "", b: "" }, hue: "", saturation: "", lightness: "", cubeFace: "", layer: "" }
				zccItem.zcc = data.zcc
				zccItem.hex = data.hex;
				zccItem.rgb = data.rgb;
				zccItem.cmyk = data.cmyk;
				zccItem.hue = Math.round(data.hsl.h).toString()
				zccItem.saturation = Math.round(data.hsl.s * 100).toString()
				zccItem.lightness = Math.round(data.hsl.l * 100).toString()
				zccItem.cubeFace = data.cubeFace
				zccItem.layer = data.layer
				let result = { item: zccItem }
				resolve(result)
			}, (error) => {

				self.spinner.hide();

				if (error.status == 404) {

					alert('You have entered an invalid ZCC#.');
				} else {
					alert(error.error.message)
				}
			})
		})
	}
	createScrollScript() {
		var s = document.createElement('script');
		s.setAttribute('src', '../../../assets/scrollbar.js');
		s.onload = () => {
			jQuery('body').find("#zcc-color-examples").customScrollbar();
		};
		document.body.appendChild(s);
	}

	public filesToUpload;
	public uploadformdata;
	public importFileName = '';
	public addedFileName = ''

	fileChangeEvent(fileInput) {
		if (fileInput.target.files.length == 0) {
			return
		}
		var file = fileInput.target.files[0];
		var str = file.name;
		this.addedFileName = str;
		var ase = str.indexOf(".ase");
		var aco = str.indexOf(".aco");
		if (ase > -1 || aco > -1) {
			this.filesToUpload = <Array<File>>fileInput.target.files;
			const formData: any = new FormData();
			const files: Array<File> = this.filesToUpload;

			for (let i = 0; i < files.length; i++) {
				formData.append("uploads[]", files[i], files[i]['name']);
			}
			this.uploadformdata = formData;
		} else {
			if (file.type != 'image/jpeg' && file.type != 'image/png') {
				this.addedFileName = '';
				alert('Please select PNG or JPG file'); return;
			}
			this.filesToUpload = <Array<File>>fileInput.target.files;
			const formData: any = new FormData();
			const files: Array<File> = this.filesToUpload;

			for (let i = 0; i < files.length; i++) {
				formData.append("uploads[]", files[i], files[i]['name']);
			}
			this.uploadformdata = formData;
		}
		this.importFileData();
	}
	importFileData() {
		if (!this.uploadformdata) {
			this.addedFileName = '';
			alert('Select a PNG or JPEG file.')
			return;
		}
		var emptycolorbox = true;
		for (var sw = 0; sw < this.gm.palettesModel.selectedPalette.swatches.length; sw++) {
			var color = this.gm.palettesModel.selectedPalette.swatches[sw];
			if (color.empty == false) {
				emptycolorbox = false;
			}
		}
		this.spinner.show();
		this.apiService.uploadFile(this.uploadformdata).subscribe((data: any) => {
			this.spinner.hide();
			data = JSON.parse(data);
			if (!data) {
				this.addedFileName = '';
				alert('No data found');
				return;
			}
			if (emptycolorbox) {
				this.replaceSwatches(data);
			} else {
				let self = this
				self.confirmationDialogService.fileConfirm('SWATCH FILE LOAD OPTIONS', 'We\'ve noted a swatch file currently in use. Do you wish to CANCEL this process, MERGE the new file with the existing swatch file, or REPLACE the existing swatch file with the file being loaded?')
					.then((confirmed) => {
						if (confirmed == 1) {
							data.swatches = data.swatches.reverse()
							for (var j = 0; j < self.gm.palettesModel.selectedPalette.swatches.length; j++) {
								// var swatch = this.gm.palettesModel.selectedPalette.swatches[j];
								if (self.gm.palettesModel.selectedPalette.swatches[j].empty == true && data.swatches.length > 0) {
									var respdata = data.swatches.pop();
									self.gm.palettesModel.selectedPalette.swatches[j].empty = false;
									self.gm.palettesModel.selectedPalette.swatches[j].rgb = respdata.hex;
									self.gm.palettesModel.selectedPalette.swatches[j].rgbfull = respdata.red + ',' + respdata.green + ',' + respdata.blue;
									self.gm.palettesModel.selectedPalette.swatches[j].zcc = respdata.zcc;
									self.gm.palettesModel.selectedPalette.swatches[j].refname = respdata.referenceName;
									self.gm.palettesModel.selectedPalette.swatches[j].refnumber = respdata.referenceNumber;
								}
							}
							self.gm.palettesModel.writeDefaultFile();
							self.ref.markForCheck();
							//swatchGrid

						} else if (confirmed == 2) {
							this.replaceSwatches(data);
							self.ref.markForCheck();
						}
					})
					.catch(() => jQuery('#removeDuplicates').prop('checked', false));
			}
		}, (error) => {
			this.spinner.hide();
			// this.swatchFIleTag.nativeElement.value = "";
			//alert(error.error.message);
			alert(error.error.message);
		})


	}

	replaceSwatches(data) {
		this.importFileName = this.addedFileName
		for (var i = 0; i < 64; i++) {
			this.gm.palettesModel.selectedPalette.swatches[i].empty = true
		}
		this.gm.palettesModel.writeDefaultFile();
		for (var i = 0; i < data.swatches.length; i++) {
			var color = data.swatches[i];
			this.gm.palettesModel.selectedPalette.swatches[i].empty = false;
			this.gm.palettesModel.selectedPalette.swatches[i].rgb = color.hex;
			this.gm.palettesModel.selectedPalette.swatches[i].rgbfull = color.red + ',' + color.green + ',' + color.blue;
			this.gm.palettesModel.selectedPalette.swatches[i].zcc = color.zcc;
			this.gm.palettesModel.selectedPalette.swatches[i].refname = color.referenceName;
			this.gm.palettesModel.selectedPalette.swatches[i].refnumber = color.referenceNumber;
			if (this.gm.palettesModel.selectedPalette.shouldShowMissingTags == true) {
				if (this.gm.palettesModel.selectedPalette.swatches[i].refnumber == '' || this.gm.palettesModel.selectedPalette.swatches[i].refname == '') {
					this.gm.palettesModel.selectedPalette.swatches[i].missingRefTag = true;
				}
				else {
					this.gm.palettesModel.selectedPalette.swatches[i].missingRefTag = false;
				}
			}
			else {
				this.gm.palettesModel.selectedPalette.swatches[i].missingRefTag = false
			}
			// this.gm.palettesModel.selectedPalette.swatches[i].hslFull = this.zccHsl;
			// this.gm.palettesModel.selectedPalette.swatches[i].hue = this.zccHsl.h;
			// this.gm.palettesModel.selectedPalette.swatches[i].saturation = this.zccHsl.s;
			// this.gm.palettesModel.selectedPalette.swatches[i].lightness = this.zccHsl.l;
		}
		this.gm.palettesModel.writeDefaultFile();
	}

	applyAction() {
		if (this.selectedColorOption == undefined) {
			let alertMessage = "Please select a COLOR THEORY or COLOR HAROMONY"
			this.confirmationDialogService.alert('ALERT!', alertMessage)
				.then((confirmed) => {
					if (confirmed) {
					}

				})
				.catch(

			);
			return
		}
		if (!this.gm.palettesModel.selectedSwatch.selected) {
			let alertMessage = "Please select a SWATCH in WORKSPACE"
			this.confirmationDialogService.alert('ALERT!', alertMessage)
				.then((confirmed) => {
					if (confirmed) {
					}

				})
				.catch(

			);
			return
		}



		var item = this.harmonyPaletteOptions.find(obj => {
			return obj.formatID == this.selectedColorOption
		})
		if (item != undefined) {
			if (this.selectedSwatchNumber == undefined) {
				let alertMessage = "Please select NUMBER OF SWATCHES"
				this.confirmationDialogService.alert('ALERT!', alertMessage)
					.then((confirmed) => {
						if (confirmed) {
						}

					})
					.catch(

				);
				return
			}
			//call color harmony
			this.applyColorHarmony()

		}
		else {
			//call color theory
			this.ApplyColorTheory()
		}


	}

	savePalleteAction() {
		this.router.navigate(['swatch-exchange'], { queryParams: { savePalleteBuilder: 1 } });
	}
	addAllSwatchAction() {
		this.clearAllPallets()
		let pbmSwatches = this.gm.palettesBuilderModel.selectedPalette.swatches
		for (let i = 0; i < pbmSwatches.length; i++) {
			const pbmSwatch = pbmSwatches[i];
			for (var j = 0; j < this.gm.palettesModel.selectedPalette.swatches.length; j++) {
				var swatch = this.gm.palettesModel.selectedPalette.swatches[j];
				if (swatch.empty) {
					this.gm.palettesModel.selectedPalette.swatches[i].empty = pbmSwatch.empty;
					this.gm.palettesModel.selectedPalette.swatches[i].rgb = pbmSwatch.rgb;
					this.gm.palettesModel.selectedPalette.swatches[i].rgbfull = pbmSwatch.rgbFull;
					this.gm.palettesModel.selectedPalette.swatches[i].zcc = pbmSwatch.zcc;
					this.gm.palettesModel.selectedPalette.swatches[i].hslFull = pbmSwatch.hslFull
					this.gm.palettesModel.selectedPalette.swatches[i].hue = pbmSwatch.hue;
					this.gm.palettesModel.selectedPalette.swatches[i].saturation = pbmSwatch.saturation;
					this.gm.palettesModel.selectedPalette.swatches[i].lightness = pbmSwatch.lightness;
					this.gm.palettesModel.writeDefaultFile();
					break;
				}
			}

		}
		this.ref.markForCheck();
	}
	addSwatchAction() {
		let selectedSwatch = this.gm.palettesBuilderModel.selectedSwatch
		if (selectedSwatch.selected) {
			this.gm.deletedAllStack = []
			if (this.gm.palettesModel.selectedSwatch.selected) {
				this.gm.addToRedoStack();
				this.gm.palettesModel.selectedSwatch.empty = selectedSwatch.empty;
				this.gm.palettesModel.selectedSwatch.rgb = selectedSwatch.rgb;
				this.gm.palettesModel.selectedSwatch.rgbfull = selectedSwatch.rgbFull;
				this.gm.palettesModel.selectedSwatch.zcc = selectedSwatch.zcc;
				this.gm.palettesModel.selectedSwatch.hslFull = selectedSwatch.hslFull
				this.gm.palettesModel.selectedSwatch.hue = selectedSwatch.hue;
				this.gm.palettesModel.selectedSwatch.saturation = selectedSwatch.saturation;
				this.gm.palettesModel.selectedSwatch.lightness = selectedSwatch.lightness;
				this.gm.palettesModel.writeDefaultFile();
			} else {
				for (var i = 0; i < this.gm.palettesModel.selectedPalette.swatches.length; i++) {
					var swatch = this.gm.palettesModel.selectedPalette.swatches[i];
					if (swatch.empty) {
						this.gm.addToRedoStack();
						this.gm.palettesModel.selectedPalette.swatches[i].empty = selectedSwatch.empty;
						this.gm.palettesModel.selectedPalette.swatches[i].rgb = selectedSwatch.rgb;
						this.gm.palettesModel.selectedPalette.swatches[i].rgbfull = selectedSwatch.rgbFull;
						this.gm.palettesModel.selectedPalette.swatches[i].zcc = selectedSwatch.zcc;
						this.gm.palettesModel.selectedPalette.swatches[i].hslFull = selectedSwatch.hslFull
						this.gm.palettesModel.selectedPalette.swatches[i].hue = selectedSwatch.hue;
						this.gm.palettesModel.selectedPalette.swatches[i].saturation = selectedSwatch.saturation;
						this.gm.palettesModel.selectedPalette.swatches[i].lightness = selectedSwatch.lightness;
						this.gm.palettesModel.writeDefaultFile();
						break;
					}
				}
			}
		}
		this.ref.markForCheck();

	}

	ApplyColorTheory() {
		let self = this
		switch (this.selectedColorOption) {

			case 'complementary':
				this.spinner.show();
				this.apiService.generateHueVariation("0,180", this.gm.palettesModel.selectedSwatch.rgb).subscribe((data: any) => {
					this.spinner.hide();
					// self.gm.deletedAllStack = []
					let dataObj = data["data"]
					self.addPalettsToPalleteWorkspace(dataObj);
				}, (error) => {
					this.spinner.hide();
					alert(error.error.message)
				})

				break;
			case 'splitcomplementary':
				this.spinner.show();
				this.apiService.generateHueVariation("150,0,-150", this.gm.palettesModel.selectedSwatch.rgb).subscribe((data: any) => {
					this.spinner.hide();
					// self.gm.deletedAllStack = []
					let dataObj = data["data"]
					self.addPalettsToPalleteWorkspace(dataObj);
				}, (error) => {
					this.spinner.hide();
					alert(error.error.message)
				})

				break;
			case 'analogous':

				this.spinner.show();
				this.apiService.generateHueVariation("30,15,0,-15,30", this.gm.palettesModel.selectedSwatch.rgb).subscribe((data: any) => {
					this.spinner.hide();
					// self.gm.deletedAllStack = []
					let dataObj = data["data"]
					self.addPalettsToPalleteWorkspace(dataObj);
				}, (error) => {
					this.spinner.hide();
					alert(error.error.message)
				})

				break;
			case 'triad':
				this.spinner.show();
				this.apiService.generateHueVariation("120,0,-120", this.gm.palettesModel.selectedSwatch.rgb).subscribe((data: any) => {
					this.spinner.hide();
					// self.gm.deletedAllStack = []
					let dataObj = data["data"]
					self.addPalettsToPalleteWorkspace(dataObj);
				}, (error) => {
					this.spinner.hide();
					alert(error.error.message)
				})

				break;
			case 'terad':
				this.spinner.show();

				this.apiService.generateHueVariation("180, 60, 0, -120", this.gm.palettesModel.selectedSwatch.rgb).subscribe((data: any) => {
					this.spinner.hide();
					// self.gm.deletedAllStack = []
					let dataObj = data["data"]
					self.addPalettsToPalleteWorkspace(dataObj);
				}, (error) => {
					this.spinner.hide();
					alert(error.error.message)
				})

				break;

			case 'square':
				this.spinner.show();
				this.apiService.generateHueVariation("180, 90, 0, -90", this.gm.palettesModel.selectedSwatch.rgb).subscribe((data: any) => {
					this.spinner.hide();
					// self.gm.deletedAllStack = []
					let dataObj = data["data"]
					self.addPalettsToPalleteWorkspace(dataObj);
				}, (error) => {
					this.spinner.hide();
					alert(error.error.message)
				})

				break;
		}
	}

	applyColorHarmony() {
		console.log('this.selectedColorOption',this.selectedColorOption)
		//create the pallete

		let dynamicSwatchLength = parseInt(this.selectedSwatchNumber, 10)
		let self = this

		switch (this.selectedColorOption) {
			case 'hueharmony':
				let hueAngleString = "";
				let variation = 360 / dynamicSwatchLength
				for (let i = variation; i <= 360; i += variation) {
					hueAngleString += i + ','
				}
				hueAngleString = hueAngleString.substr(0, hueAngleString.length - 1)
				this.spinner.show();
				this.apiService.generateHueVariation(hueAngleString, this.gm.palettesModel.selectedSwatch.rgb).subscribe((data: any) => {
					this.spinner.hide();
					self.gm.deletedAllStack = []
					let dataObj = data["data"]
					self.addPalettsToPalleteWorkspace(dataObj);
				}, (error) => {
					this.spinner.hide();
					alert(error.error.message)
				})

				break;
			case 'splittonal':
				this.spinner.show();
				this.apiService.generateSplitTonalVariation(dynamicSwatchLength.toString(), this.gm.palettesModel.selectedSwatch.rgb).subscribe((data: any) => {
					this.spinner.hide();
					self.gm.deletedAllStack = []
					let dataObj = data["data"]
					self.addPalettsToPalleteWorkspace(dataObj);
				}, (error) => {
					this.spinner.hide();
					alert(error.error.message)
				})

				break;
			case 'shade':

				this.spinner.show();
				this.apiService.generateSaturationVariations(dynamicSwatchLength.toString(), false, true, this.gm.palettesModel.selectedSwatch.rgb).subscribe((data: any) => {
					this.spinner.hide();
					self.gm.deletedAllStack = []
					let dataObj = data["data"]
					self.addPalettsToPalleteWorkspace(dataObj);
				}, (error) => {
					this.spinner.hide();
					alert(error.error.message)
				})

				break;
			case 'spectral':
				this.spinner.show();
				this.apiService.generateSaturationVariations(dynamicSwatchLength.toString(), true, true, this.gm.palettesModel.selectedSwatch.rgb).subscribe((data: any) => {
					this.spinner.hide();
					self.gm.deletedAllStack = []
					let dataObj = data["data"]
					self.addPalettsToPalleteWorkspace(dataObj);
				}, (error) => {
					this.spinner.hide();
					alert(error.error.message)
				})

				break;
			case 'tint':
				this.spinner.show();
				this.apiService.generateSaturationVariations(dynamicSwatchLength.toString(), false, false, this.gm.palettesModel.selectedSwatch.rgb).subscribe((data: any) => {
					this.spinner.hide();
					self.gm.deletedAllStack = []
					let dataObj = data["data"]
					self.addPalettsToPalleteWorkspace(dataObj);
				}, (error) => {
					this.spinner.hide();
					alert(error.error.message)
				})

				break;
		}



	}

	scrollWorkspaceToTop() {
		jQuery('.colors').scrollTop(0)
	}



	addPalettsToPalleteWorkspace(dataObj) {
		var self = this;
		let paletteColors: Array<any> = []
		for (var j = 0; j < dataObj.length; j++) {
			let zccObj = dataObj[j]
			var hex = "#" + zccObj.hex
			let paletteColor = { hex: zccObj.hex, hashHex: hex, zcc: zccObj.zcc }
			paletteColors.push(paletteColor)
		}

		self.removeAllPalleteBuilderPallets();
		let hashHex;
		for (let i = 0; i < paletteColors.length; i++) {
			if (paletteColors[i] && paletteColors[i].hashHex) {
				hashHex = paletteColors[i].hashHex;
			} else {
				hashHex = '#CCCCCC';
			}
			for (let j = 0; j < this.gm.palettesBuilderModel.selectedPalette.swatches.length; j++) {
				let swatch = this.gm.palettesBuilderModel.selectedPalette.swatches[j];
				if (swatch.empty === true) {
					self.gm.colorUtility.generateZCCColors(hashHex, paletteColors[i].zcc, swatch);
					swatch.empty = false;
					break;
				}
			}
		}
		// this.scrollWorkspaceToTop()
		this.gm.palettesBuilderModel.writeDefaultFile();
	}

	removeAllPalleteBuilderPallets() {
		// this.gm.deletedAllStack = [];
		for (let i = 0; i < this.gm.palettesBuilderModel.selectedPalette.swatches.length; i++) {
			let swatch = this.gm.palettesBuilderModel.selectedPalette.swatches[i];
			// if (swatch.empty == false) {
			// 	let deletedSwatch = Object.assign({}, swatch);	
			// 	let deletedArr = {index:i, data:deletedSwatch};
			// 	this.gm.deletedAllStack.push(deletedArr);
			// }
		}
		let palette = this.gm.palettesBuilderModel.selectedPalette
		this.gm.palettesBuilderModel.removePalette(palette);
		this.gm.palettesBuilderModel.writeDefaultFile();
		// this.gm.deletedStack = [];
	}
}

