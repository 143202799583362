import {Injectable, Component, OnInit, ViewEncapsulation, Injector, Input, HostListener } from '@angular/core';
import { BaseComponentSecondary } from 'src/app/base.component.secondary';
import {SwatchChangedEvent} from "../../events/swatch.changed.event";
import {SwatchModel} from "../../models/swatch.model";
import {PaletteModel} from "../../models/palette.model";
import {PalettesModel} from "../../models/palettes.model";
import { ColorPickerService } from '../../components/color-picker/color-picker.service';
declare var jQuery: any;

@Injectable({
	providedIn: 'root'
  })
@Component({
	selector: 'common-mini-workspace',
	templateUrl: './mini-workspace.html',
	styleUrls: ['./mini-workspace.scss'],
	encapsulation: ViewEncapsulation.None
})
export class MiniWorkspace extends BaseComponentSecondary implements OnInit {
	swatchesList: any[] = []
	@Input() palette: PaletteModel;
	@Input() palettes: PalettesModel;
	@Input() swatch: SwatchModel;
	@Input() isComparison: Boolean;
	screenWidth = window.innerWidth - 60
	setWidth = window.innerHeight - 115
	viewCount = 16
	constructor(public injector: Injector) {
		super(injector);
	}

	ngOnInit() {
		var widthUL = jQuery('ul.workspacecolors').outerWidth();
		var boxH = jQuery(widthUL / 16);
		jQuery('ul.workspacecolors').css('height',boxH[0]+'px');
		this.createScrollScript()
		this.screenWidth = window.innerWidth - 60
		this.setWidth = window.innerHeight - 115
		this.viewCount = (window.innerWidth > 1024)? 16: 8
	}
	@HostListener('window:resize', ['$event'])
	onResize(event){

		this.screenWidth = window.innerWidth - 60
		this.setWidth = window.innerHeight - 115
		this.viewCount = (window.innerWidth > 1024)? 16: 8
		var widthUL = jQuery('ul.workspacecolors').outerWidth();
		var boxH = jQuery(widthUL / 16);
		jQuery('ul.workspacecolors').css('height',boxH[0]+'px');
	}

	contrastingFontColor(swatch): string {
    return ColorPickerService.isDarkColor(swatch.rgb) ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)';
  }


	missingTagBlack(swatch): any {
		if(swatch.empty || this.palette.shouldShowMissingTags == false){
			return false;
		} else {
			return swatch.missingRefTag;
		}
	}
	dropped(event: any, swatchIndex: number) : void {
		this.gm.addToRedoStack();
		if (typeof event.dragData.dragIndex != 'undefined') {
		  // move swatch from dragIndex to swatchIndex
		  var element = this.palette.swatches[event.dragData.dragIndex];
		  this.palette.swatches.splice(event.dragData.dragIndex, 1);
		  this.palette.swatches.splice(swatchIndex, 0, element);
		} else {
		  let indexSwatch = this.palette.swatches[swatchIndex];
		  indexSwatch.copy(event.dragData.swatch);
		  if (indexSwatch.refnumber == '' || indexSwatch.refname == '') {
			indexSwatch.missingRefTag = true;
		  }
		  else{
			indexSwatch.missingRefTag = false;
		  }
		  this.palettes.selectedSwatch.selected = false;
		  this.palettes.selectedSwatch = indexSwatch;
	
		if (this.palettes.selectedPalette.savedReferenceName.length > 0) {
		  this.palettes.selectedPalette.enableMerge = true;
	  }
		}
		this.palettes.selectedPalette.palletSaved = false;
		this.palettes.writeDefaultFile();
	}

	selectSwatch(event):void {
		if(event.selected == true){
			event.selected = false;
		} else {
			this.palettes.selectedPalette.deselectAllSwatches();

			var o = new SwatchChangedEvent({
				"oldSwatchId": this.palettes.selectedSwatch.id,
				"oldPaletteId": this.palettes.selectedPalette.id,
				"newSwatch": event,
				"newPalette": this.palette
			});
			event.selected = true;
			this.palettes.selectedSwatch = event;
		// this.gm.colorUtility.generateColors(this.palettes.selectedSwatch.rgb, this.palettes.selectedSwatch);
			this.palettes.selectedPalette = this.palette;
			this.palettes.data.activePaletteId = this.palette.id;
			this.palettes.swatchChanged.emit(o);
		}
        
	}
	

	deleteSwatch(swatch){
		if(swatch.empty){
			alert("The swatch is already empty.");
			return;
		} else {
			this.gm.addToRedoStack();
		  for ( var i = 0; i < this.palette.swatches.length; i ++ ) {
			if (this.palette.swatches[i].id === swatch.id) {
				let deletedSwatch = Object.assign({}, this.palette.swatches[i]);
				let index = this.palettes.selectedPalette.swatches.findIndex(x=>x.id==this.palette.swatches[i].id);
				let deletedArr = {index:index, data:deletedSwatch};
				this.gm.deletedStack.push(deletedArr);
			  this.palette.swatches[i].empty = true;
			  this.palette.swatches[i].refname = ""
			  this.palette.swatches[i].refnumber = ""
			  this.palette.swatches[i].zcc = "ZCC-0000"
			  this.palette.swatches[i].contrastingFontColor = "white";
			  this.palettes.selectedSwatch = new SwatchModel({});
			  this.palettes.updatePalette(this.palette);
			  this.palettes.writeDefaultFile();
			}
		  }
		}
	}

	createScrollScript () {
		var s = document.createElement( 'script' );
		s.setAttribute( 'src', '../../../assets/scrollbar.js' );
		s.onload=() =>{
			jQuery('body').find("#workspacecolors").customScrollbar();
		};
		document.body.appendChild( s );
	}

		
		contrastingCheckIconColor(swatch): string {
			console.log('is dark',ColorPickerService.isDarkColor(swatch.rgb))
		return ColorPickerService.isDarkColor(swatch.rgb) ? 'check' : 'black-check';
		}
		
		contrastingCrossIconColor(swatch): string {
			console.log('swatch',swatch);
			console.log('ColorPickerService.isDarkColor(swatch.rgb)',ColorPickerService.isDarkColor(swatch.rgb))
		return ColorPickerService.isDarkColor(swatch.rgb) ? 'close' : 'black-close';
		}

}