import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PdfViewerComponent } from "ng2-pdf-viewer";

@Component({
  selector: "app-help",
  templateUrl: "./help.component.html",
  styleUrls: ["./help.component.scss"],
  providers: [PdfViewerComponent],
})
export class HelpComponent implements OnInit {
  src: string; // "assets/help.pdf";

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      switch (params["section"]) {
        case "capture":
          this.src = "assets/capture.pdf";
          break;
        case "swatch-exchange":
          this.src = "assets/swatch-exchange.pdf";
          break;
        case "image-manager":
          this.src = "assets/image-manager.pdf";
          break;
        case "imageexchange":
          this.src = "assets/image-exchange.pdf";
          break;
        default:
          this.src = "assets/help.pdf";
          break;
      }
    });
  }
}
