import { Component, OnInit } from "@angular/core";
import { PdfViewerComponent } from "ng2-pdf-viewer";

@Component({
  selector: "app-help",
  templateUrl: "./help.component.html",
  styleUrls: ["./help.component.scss"],
  providers: [PdfViewerComponent],
})
export class HelpComponent implements OnInit {
  src = "assets/help.pdf";

  constructor() {}

  ngOnInit() {}
}
