import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { NgxSpinnerModule } from "ngx-spinner";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app";
import { ColorPickerPage } from "./pages/color-picker/color-picker";
import { Error404Page } from "./pages/error-404/error-404";
import { DyeFormulasPage } from "./pages/dye-formulas/dye-formulas";
import { ColorSamplerPage } from "./pages/color-sampler/color-sampler";
import { ColorManagerPage } from "./pages/color-manager/color-manager";
import { DigitalImageManager } from "./pages/digital-image-manager/digital-image-manager.page";
import { Sample } from "./pages/sample/sample.page";
import { HomePage } from "./pages/home/home.page";
import { FileExchange } from "./pages/fileexchange/file-exchange.page";
import { ImageExchange } from "./pages/imageexchange/image-exchange.page";
import { SwatchPicker } from "./pages/swatch-picker/swatch-picker.page";
// import { SwatchHalo } from './pages/swatch-aura/swatch-halo.page';
import { FileExchangePage } from "./pages/file-exchange/file-exchange";
// import { ColorPicker2Component } from './colorpicker2/colorPicker2.component'
import { HeaderCommon } from "./commons/header/header";
import { FooterCommon } from "./commons/footer/footer";
import { TabsCommon } from "./commons/tabs/tabs";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BatchDataConversionsPage } from "./pages/batch-data-conversions/batch-data-conversions";
import { EnterAColorCodePage } from "./pages/enter-a-color-code/enter-a-color-code";
import { FileConversionPage } from "./pages/file-conversions/file-conversions";
import { ColorsBoxCommon } from "./commons/colors-box/colors-box";
import { ImageExchangeColorsBoxCommon } from "./commons/imageExchange-colors-box/imageExchange-colors-box";
import { MiniWorkspace } from "./commons/mini-workspace/mini-workspace";
import { FullWorkspace } from "./commons/full-workspace/full-workspace";
import { RemoveDuplicate } from "./commons/remove-duplicate/remove-duplicate";
import { SemiWorkspace } from "./commons/semi-workspace/semi-workspace";
import { ColorsFileBoxCommon } from "./commons/colors-file-box/colors-file-box";
import { LoginComponent } from "./login/login.component";
import { GlobalModel } from "./models/global.model";
import { NgColorModule } from "ng-color";
import { SelectableSwatchComponent } from "./components/selectable-swatch.component";
import { alertComponent } from "./components/alert.component";
import { HuePickerCanvas } from "./colorpicker2/huePickerCanvas.component";
import { SaturationPickerCanvas } from "./colorpicker2/saturationPickerCanvas.component";
import { AddPalettePromptComponent } from "./components/add-palette-prompt.component";
import { ClearPromptComponent } from "./components/clear-prompt.component";
import { ConfirmComponent } from "./components/confirm.component";
import { errorComponent } from "./components/error.component";
import { ExitDownloadComponent } from "./components/exitdownload.component";
import { FileExistComponent } from "./components/fileexist.component";
import { PromptComponent } from "./components/prompt.component";
import { SelectablePaletteFileComponent } from "./components/selectable-palette-file.component";
import { SelectableSwatchFileComponent } from "./components/selectable-swatch-file.component";

// import {SelectablePaletteComponent} from "./components/selectable-palette.component";
import { UpdateAppConfirmComponent } from "./components/updateAppconfirm.component";
import { TextDirective } from "./components/color-picker/color-picker.directive";
import { SliderDirective } from "./components/color-picker/color-picker.directive";
import { DialogComponent } from "./components/color-picker/color-picker.directive";
import { ColorPickerModule } from "./components/color-picker";
import { BootstrapModalModule } from "ng2-bootstrap-modal";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FileConfirmationDialogComponent } from "./confirmation-dialog/file-confirmation-dialog.component";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { AlertDialogComponent } from "./confirmation-dialog/alert-dialog.component";
import { ConfirmationDialogService } from "./confirmation-dialog/confirmation-dialog.service";
import { FormModalComponent } from "./form-modal/form-modal.component";
import { NgbdModalContent } from "./commons/header/header";
import { GetdeltaeComponent } from "./pages/getdeltae/getdeltae.component";
import { DataconversionComponent } from "./pages/dataconversion/dataconversion.component";
import { ColorharmonyComponent } from "./pages/colorharmony/colorharmony.component";
import { ColortheoryComponent } from "./pages/colortheory/colortheory.component";
import { MenusComponent } from "./menus/menus.component";
import { AuthGuard } from "./auth.guard";
import { LoginGuard } from "./login.guard";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { DndModule } from "ng2-dnd";
import { PaletteBuilderComponent } from "./pages/palette-builder/palette-builder.component";
import { HelpComponent } from "./help/help.component";
import { PdfViewerModule } from "ng2-pdf-viewer";

declare global {
  interface Window {
    EyeDropper?: any;
  }
}
@NgModule({
  declarations: [
    NgbdModalContent,
    AppComponent,
    ColorPickerPage,
    Error404Page,
    DyeFormulasPage,
    ColorSamplerPage,
    ColorManagerPage,
    DigitalImageManager,
    Sample,
    HomePage,
    FileExchange,
    ImageExchange,
    FileExchangePage,
    SwatchPicker,
    // SwatchHalo,
    // ColorPicker2Component,
    BatchDataConversionsPage,
    EnterAColorCodePage,
    FileConversionPage,
    ColorsBoxCommon,
    ImageExchangeColorsBoxCommon,
    MiniWorkspace,
    FullWorkspace,
    RemoveDuplicate,
    SemiWorkspace,
    ColorsFileBoxCommon,
    HeaderCommon,
    FooterCommon,
    TabsCommon,
    LoginComponent,
    SelectableSwatchComponent,
    alertComponent,
    HuePickerCanvas,
    SaturationPickerCanvas,
    AddPalettePromptComponent,
    ClearPromptComponent,
    ConfirmComponent,
    errorComponent,
    ExitDownloadComponent,
    FileExistComponent,
    PromptComponent,
    SelectablePaletteFileComponent,
    // SelectablePaletteComponent,
    SelectableSwatchFileComponent,
    UpdateAppConfirmComponent,
    TextDirective,
    SliderDirective,
    DialogComponent,
    ConfirmationDialogComponent,
    AlertDialogComponent,
    FileConfirmationDialogComponent,
    FormModalComponent,
    GetdeltaeComponent,
    DataconversionComponent,
    ColorharmonyComponent,
    ColortheoryComponent,
    MenusComponent,
    PaletteBuilderComponent,
    HelpComponent,
  ],
  imports: [
    BrowserModule,
    PdfViewerModule,
    NgxSpinnerModule,
    NgxSliderModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgColorModule,
    HttpClientModule,
    ColorPickerModule,
    DndModule.forRoot(),
    BootstrapModalModule.forRoot({ container: document.body }),
    NgbModule.forRoot(),
  ],
  entryComponents: [
    alertComponent,
    ClearPromptComponent,
    ConfirmationDialogComponent,
    AlertDialogComponent,
    FileConfirmationDialogComponent,
    FormModalComponent,
    NgbdModalContent,
  ],
  providers: [GlobalModel, ConfirmationDialogService, AuthGuard, LoginGuard],
  bootstrap: [AppComponent],
})
export class AppModule {}
