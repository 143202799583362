import { Component, OnInit, Injector } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { BaseComponent } from "../base.component";

declare var jQuery: any;

@Component({
  selector: "menus",
  templateUrl: "./menus.component.html",
  styleUrls: ["./menus.component.scss"],
})
export class MenusComponent extends BaseComponent implements OnInit {
  menus: any[];
  showBackButton: boolean = false;
  backUrl: string = "";
  title: string[];
  currentPath: string;
  constructor(
    public injector: Injector,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super(injector);
    if (
      this.router.getCurrentNavigation().previousNavigation &&
      (this.router.url == "/capture" || this.router.url == "/create")
    ) {
      this.showBackButton = true;
      this.backUrl = this.router
        .getCurrentNavigation()
        .previousNavigation.extractedUrl.toString();
    }
    this.title = this.route.snapshot.data.title;

    this.currentPath = this.router.url;
  }

  ngOnInit() {
    jQuery("body").click(function (evt) {
      if (!jQuery(evt.target).closest(".nav-humburger img").length) {
        jQuery("#toggleMenu").slideUp();
      }
    });
    // jQuery('body').click('#show-popup', function () {
    //   let value = localStorage.getItem('popup') || false
    //   localStorage.setItem('popup', !)
    // })
    jQuery(".nav-humburger img").click(function () {
      jQuery(this).next("ul").slideToggle();
    });
  }
  togglePopup() {
    let popUpValue = localStorage.getItem("popup");
    try {
      if (popUpValue === "true") localStorage.setItem("popup", "false");
      else localStorage.setItem("popup", "true");
    } catch (e) {
      console.log("Local Storage is full, Please empty data");
    }

    window.location.reload();
  }
  goBack() {
    // this.location.back()
    window.location.href = this.backUrl;
  }
  openHelp() {
    const currentUrl = window.location.href; // Get the current URL
    const newSegment = `help?section=${this.currentPath.replace("/", "")}`; // Dynamic replacement value
    let updatedUrl = this.replaceAfterLastSlash(currentUrl, newSegment);
    const windowFeatures =
      "width=800,height=600,left=200,top=100,resizable=yes,scrollbars=yes";

    window.open(updatedUrl, "_blank", windowFeatures);
  }
  replaceAfterLastSlash(url: string, newSegment: string): string {
    // Find the index of the last slash
    const lastSlashIndex = url.lastIndexOf("/");
    // Replace everything after the last slash with the new segment
    return url.substring(0, lastSlashIndex + 1) + newSegment;
  }
  logout() {
    sessionStorage.removeItem("USER_DETAILS");
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigateByUrl("/login");
  }
}
